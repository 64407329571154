import React from 'react';
import LandingPage from './LandingPage/LandingPage';
import Services from './Services/Services';
import Highlight from './HighlightSection/Highlight';
import Arcade from './Arcade/Arcade';
import ScreenShotScreen from './ScreenShotScreen/ScreenShotScreen';
import TabScreen from './TabScreen/TabScreen';
import LearnAndGrowSection from './ColorScreens/LearnAndGrowSection';
import SecuritySection from './ColorScreens/SecuritySection';
import PricingModelAd from './ColorScreens/PricingModelAd';
import PricingComponent from './PricingPage/PricingComponent';
import AboutSection from './AboutSection/AboutSection';
import { SCREEN_SHOT_CONTENT } from './ScreenShotScreen/export';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Shipmnts | HUB</title>
        <meta name="description" content="Shipmnts HUB - Your Logistics Manager" />
      </Helmet>
      <LandingPage />
      <Services />
      <Highlight />
      <Arcade />
      <ScreenShotScreen {...SCREEN_SHOT_CONTENT['customer_centric_features']} />
      <TabScreen />
      <ScreenShotScreen {...SCREEN_SHOT_CONTENT['actionable_insight']} />
      <ScreenShotScreen {...SCREEN_SHOT_CONTENT['productivity_and_multi_party_collaboration']} />
      <LearnAndGrowSection />
      <SecuritySection />
      <PricingModelAd />
      <PricingComponent />
      <AboutSection />
    </>
  );
};

export default Home;
